export default (function () {
  function trackEvent() {
    let ev_startChat = {
      eventName: 'startChat',
      eventAction: 'track',
      eventPoints: 0,
      type: 'dynamic',
      timestamp: +new Date(),
    };
    digitalData?.event.push(ev_startChat);
  }

  function hideTrustBadge() {
    let trustBadgeContainer = document.body.querySelector(
      'div[id^=trustbadge-container]',
    );
    if (trustBadgeContainer !== null) {
      trustBadgeContainer.style.zIndex = 3000;
    }
  }
  function addObserverChat(targetNode) {
    let chatStarted = false;
    let config = {
      attributes: false,
      childList: true,
      subtree: true,
    };
    let callback = function (mutationsList) {
      for (let i = 0; i < mutationsList.length; i++) {
        let mutation = mutationsList[i];
        if (mutation.type === 'childList') {
          if (
            typeof mutation.addedNodes[0] !== 'undefined' &&
            mutation.addedNodes[0].getAttribute('name') ===
              'iAdvize chatbox opened'
          ) {
            chatStarted = true;
            observer.disconnect();
          }
        }
      }
      if (chatStarted) {
        chatStarted = false;
        trackEvent();
        hideTrustBadge();
        setTimeout(function () {
          observer.observe(targetNode, config);
        }, 1000);
      }
    };
    let observer = new MutationObserver(callback);
    observer.observe(targetNode, config);
  }

  function init() {
    let targetNode = document.body;
    let config = {
      attributes: false,
      childList: true,
    };
    let observer = new MutationObserver(function (mutationsList) {
      for (let i = 0; i < mutationsList.length; i++) {
        let mutation = mutationsList[i];
        if (mutation.type === 'childList') {
          if (
            typeof mutation.addedNodes[0] !== 'undefined' &&
            mutation.addedNodes[0].getAttribute('id') === 'iadvize-container'
          ) {
            addObserverChat(mutation.addedNodes[0]);
          }
        }
      }
    });

    observer.observe(targetNode, config);
  }

  return {
    init: init,
  };
})();
