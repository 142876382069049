export default (function () {
  let partials = {
    teaser:
      '<div class="{{ channel }}">' +
      '<div class="inner {{ state.online }}" style="display: none;">' +
      '<div class="heading-wrap">' +
      '<span class="icon icon__dark icon__small icon__circle"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 22"><path d="M4 22a.999.999 0 0 1-.782-1.623c.016-.021 1.437-1.829 1.718-3.722C2.446 15.409 0 12.774 0 9.461 0 4.244 5.383 0 12 0s12 4.244 12 9.461C24 14.721 18.617 19 12 19h-1.435c-1.957 2.845-6.342 2.994-6.539 3zm8-20C6.486 2 2 5.347 2 9.461c0 2.563 2.191 4.778 4.355 5.641a1 1 0 0 1 .629.929c0 1.327-.472 2.625-.98 3.636 1.155-.317 2.492-.934 3.107-2.126.172-.332.515-.541.889-.541h2c5.514 0 10-3.382 10-7.539C22 5.347 17.514 2 12 2"></path></svg></span>' +
      '<h3 class="headline role-headline size-small status-green">{{ online.headline }}</h3>' +
      '</div>' +
      '<div class="cta-wrap">' +
      '<div><p><span>{{ online.text }}</span></p></div>' +
      '<a class="button button__light button__outlined button__default" href="{{online.href}}">' +
      '<span class="button__overlay"></span>' +
      '<span class="button__text">{{ online.linkText }}</span>' +
      '</a>' +
      '</div>' +
      '</div>' +
      '<div class="inner {{ state.busy }}" style="display: none;">' +
      '<div class="heading-wrap">' +
      '<span class="icon icon__dark icon__small icon__circle"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 22"><path d="M4 22a.999.999 0 0 1-.782-1.623c.016-.021 1.437-1.829 1.718-3.722C2.446 15.409 0 12.774 0 9.461 0 4.244 5.383 0 12 0s12 4.244 12 9.461C24 14.721 18.617 19 12 19h-1.435c-1.957 2.845-6.342 2.994-6.539 3zm8-20C6.486 2 2 5.347 2 9.461c0 2.563 2.191 4.778 4.355 5.641a1 1 0 0 1 .629.929c0 1.327-.472 2.625-.98 3.636 1.155-.317 2.492-.934 3.107-2.126.172-.332.515-.541.889-.541h2c5.514 0 10-3.382 10-7.539C22 5.347 17.514 2 12 2"></path></svg></span>' +
      '<h3 class="headline role-headline size-small status-red">{{ busy.headline }}</h3>' +
      '</div>' +
      '<div class="cta-wrap">' +
      '<div><p><span>{{ busy.text }}</span></p></div>' +
      '<a class="button button__light button__outlined button__default" href="{{busy.href}}">' +
      '<span class="button__overlay"></span>' +
      '<span class="button__text">{{ busy.linkText }}</span>' +
      '</a>' +
      '</div>' +
      '</div>' +
      '<div class="inner {{ state.offline }}">' +
      '<div class="heading-wrap">' +
      '<span class="icon icon__dark icon__small icon__circle"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 22"><path d="M4 22a.999.999 0 0 1-.782-1.623c.016-.021 1.437-1.829 1.718-3.722C2.446 15.409 0 12.774 0 9.461 0 4.244 5.383 0 12 0s12 4.244 12 9.461C24 14.721 18.617 19 12 19h-1.435c-1.957 2.845-6.342 2.994-6.539 3zm8-20C6.486 2 2 5.347 2 9.461c0 2.563 2.191 4.778 4.355 5.641a1 1 0 0 1 .629.929c0 1.327-.472 2.625-.98 3.636 1.155-.317 2.492-.934 3.107-2.126.172-.332.515-.541.889-.541h2c5.514 0 10-3.382 10-7.539C22 5.347 17.514 2 12 2"></path></svg></span>' +
      '<h3 class="headline role-headline size-small status-grey">{{ offline.headline }}</h3>' +
      '</div>' +
      '<div class="cta-wrap">' +
      '<div><p><span>{{ offline.text }}</span></p></div>' +
      '<a class="button button__light button__outlined button__default" href="{{offline.href}}">' +
      '<span class="button__overlay"></span>' +
      '<span class="button__text">{{ offline.linkText }}</span>' +
      '</a>' +
      '</div>' +
      '</div>' +
      '</div>',

    marginal:
      '<div class="{{ channel }}">' +
      '<div class="{{ state.online }}" style="display: none;">' +
      '<div class="embeded_chat_code">' +
      '<strong class="chat_status online">{{ online.headline }}</strong>' +
      '<span>{{ online.text }}</span>' +
      '<a class="link-more" href="{{online.href}}">{{ online.linkText }}</a>' +
      '</div>' +
      '</div>' +
      '<div class="{{ state.busy }}" style="display: none;">' +
      '<div class="embeded_chat_code">' +
      '<strong class="chat_status online">{{ busy.headline }}</strong>' +
      '<span>{{ busy.text }}</span>' +
      '<a class="link-more" href="{{busy.href}}">{{ busy.linkText }}</a>' +
      '</div>' +
      '</div>' +
      '<div class="{{ state.offline }}">' +
      '<div class="embeded_chat_code">' +
      '<strong class="chat_status online">{{ offline.headline }}</strong>' +
      '<span>{{ offline.text }}</span>' +
      '<a class="link-more" href="{{ offline.href }}">{{ offline.linkText }}</a>' +
      '</div>' +
      '</div>' +
      '</div>',
  };

  let text = {
    teaser: {
      online: {
        headline: 'Chat Messenger <span class="chat online"></span>',
        text: 'Wir sind jetzt für dich da und beantworten gerne deine Fragen.',
        linkText: 'Chat starten <span class="svg_arrow_right"></span>',
        href: 'javascript:void(0)',
      },
      busy: {
        headline: 'Chat Messenger <span class="chat busy"></span>',
        text: 'Leider sind alle Berater in einem Gespräch.',
        linkText: 'Zur Warteschleife <span class="svg_arrow_right"></span>',
        href: 'javascript:void(0)',
      },
      offline: {
        headline: 'Chat Messenger <span class="chat offline"></span>',
        text: 'Der Chat steht Dir momentan leider nicht zur Verfügung.',
        linkText: 'Kontakt <span class="svg_arrow_right"></span>',
        href: '/ueber-uns/kontakt',
      },
    },

    marginal: {
      online: {
        headline: 'Chat verfügbar',
        text: 'Wir sind jetzt persönlich für dich da.',
        linkText: 'Zum Chat',
        href: 'javascript:void(0)',
      },
      busy: {
        headline: 'Chat Messenger',
        text: 'Leider sind alle Berater in einem Gespräch.',
        linkText: 'Zur Warteschleife',
        href: 'javascript:void(0)',
      },
      offline: {
        headline: 'Chat Messenger',
        text: 'Der Chat steht Dir momentan leider nicht zur Verfügung.',
        linkText: 'Kontakt',
        href: '/ueber-uns/kontakt',
      },
    },
  };

  let channels = {
    sales_de: {
      channel: 'livechat_ewide_sales_reactiv',
      state: {
        online: 'livechat_ewide_sales_reactiv_online livechat_ewi_online',
        busy: 'livechat_ewide_sales_reactiv_busy livechat_ewi_busy',
        offline: 'livechat_ewide_sales_reactiv_offline livechat_ewi_offline',
      },
    },
    service_de: {
      channel: 'livechat_ewide_service_reactiv',
      state: {
        online: 'livechat_ewide_service_reactiv_online livechat_ewi_online',
        busy: 'livechat_ewide_service_reactiv_busy livechat_ewi_busy',
        offline: 'livechat_ewide_service_reactiv_offline livechat_ewi_offline',
      },
    },
  };

  function render(templ, context) {
    let regEx = new RegExp(/({{)(\s*[a-zA-Z\.]+\s*)(}})/gim);
    let regExRepl;
    let rendered = templ;
    let match;

    while ((match = regEx.exec(templ)) !== null) {
      let keys = match[2].split('.');
      let data = context;

      for (let i in keys) {
        let key = keys[i].replace(/ /g, '');
        data = data[key];
      }

      data = typeof data !== 'string' ? data[key] : data;
      regExRepl = new RegExp(match[0], 'gmi');
      rendered = rendered.replace(regExRepl, data);
    }

    return rendered;
  }

  function returnContext(region, channel) {
    let data = Object.assign({}, channels[channel]);
    let out = Object.assign(data, text[region]);
    return out;
  }

  function returnTemplate(region) {
    return partials[region];
  }

  return {
    request: function (region, channel) {
      let html = render(returnTemplate(region), returnContext(region, channel));
      return html;
    },
  };
})();
