import infoButton from './ui/components/1-atoms/InfoButton/InfoButton.js';
import headerNavigation from './ui/components/3-organisms/HeaderNavigation/HeaderNavigation.js';
import cardSlider from './ui/components/3-organisms/CardSlider/CardSlider.js';
import cardSliderItem from './ui/components/2-molecules/CardSliderItem/CardSliderItem.js';
import chatEvents from './ui/components/3-organisms/FooterContact/chat/chat_iadvize_track.js';
import chatIadvizeModule from './ui/components/3-organisms/FooterContact/chat/chat_iadvize.js';
window.addEventListener('load', (event) => {
  infoButton.init();
  headerNavigation.init();
  cardSlider.init();
  cardSliderItem.init();
  chatEvents.init();
  window.chatIadvise = {
    setup: chatIadvizeModule.setup,
  };
});
