import preventMirroring from './chat_iadvize_prevent_mirroring.js';
import chatIadvizeTemplates from './chat_iadvize_templates.js';
export default (function () {
  let config = {
    sales_de: {
      'chat-container--footer': {
        teaser: 'sales_de',
      },
      'chat-container--marginal': {
        marginal: 'sales_de',
      },
      'chat-container--sales': {
        teaser: 'sales_de',
      },
      'chat-container--service': {
        teaser: 'service_de',
      },
    },
    service_de: {
      'chat-container--footer': {
        teaser: 'service_de',
      },
      'chat-container--marginal': {
        marginal: 'service_de',
      },
      'chat-container--sales': {
        teaser: 'sales_de',
      },
      'chat-container--service': {
        teaser: 'service_de',
      },
    },
  };

  function emptyNode(node) {
    while (node.firstChild) {
      node.removeChild(node.firstChild);
    }
  }

  function getConfig(page, container) {
    let obj = {};
    for (let i in config[page][container]) {
      obj = {
        region: i,
        channel: config[page][container][i],
      };
    }
    return obj;
  }

  function getTemplate(page, container) {
    let obj = getConfig(page, container);
    return chatIadvizeTemplates.request(obj.region, obj.channel);
  }

  function insertHtml(node, page, container) {
    emptyNode(node);
    node.insertAdjacentHTML('afterbegin', getTemplate(page, container));
    chatReady();
  }

  function chatReady() {
    let iAdvizeChatReady = new CustomEvent('iAdvizeChatReady');
    document.dispatchEvent(iAdvizeChatReady);
  }

  return {
    //invoked by external iAdvize script when loaded (cookie consent also required)
    setup: function (page) {
      for (let container in config[page]) {
        let node = document.getElementById(container);
        if (node === null) continue;
        insertHtml(node, page, container);
      }
      preventMirroring.init();
    },
  };
})();
