export default (function () {
  let noMirroringClass = 'idz_hide';
  let selectors = [
    'input[type="text"]',
    'input[type="password"]',
    'input[type="email"]',
    'input[type="radio"]',
    'input[type="checkbox"]',
    'textarea',
    'select',
  ];

  function init() {
    searchSelectors();
  }

  function markNoMirror(node) {
    node.classList.add(noMirroringClass);
  }

  function searchSelectors() {
    for (let i in selectors) {
      let nodes = document.querySelectorAll(selectors[i]);
      if (nodes !== null) {
        nodes.forEach(function (node) {
          markNoMirror(node);
        });
      }
    }
  }

  return {
    init: init,
  };
})();
